import React, { useState, useEffect } from "react";
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis'
import Web3 from 'web3'
import { message } from "antd";
import { minAmount, genericErc20Abi, BaseToken, mswapAddress, muftAddress, approveAmount, bettingContractAddress, bettingABI } from "contracts/constants/index";
import arrow from "../../../assets/icons/arrow.png";

const PriceBetCard = ({ setShowBet, value, winTeam, balanceArray, setIsLoading, leagueValue, match, getEventList, getTokenBalances }) => {
  const contractProcessor = useWeb3ExecuteFunction();
  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, logout, user, isInitialized } = useMoralis();
  const { Moralis } = useMoralis();
  const [activeTab, setActiveTab] = useState("0");
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    // console.log('==', value);
  }, [])

  function getBalance() {
    switch (value) {
      case 'BNB':
        return balanceArray[0]
      case 'MSWAP':
        return balanceArray[1]
      case 'MUFT':
        return balanceArray[2]
      default:
        break;
    }
  }

  function handleInputChange(e) {
    setActiveTab(e);
    const val = getBalance();
    if (e === "0") setAmount(val * 0.25)
    if (e === "1") setAmount(val * 0.5)
    if (e === "2") setAmount(val * 0.75)
    if (e === "3") setAmount(val * 0.99)
    console.log(getBalance());
  }

  async function placeBetBNB() {
    try {
      setIsLoading(true)
      // deposit_bnb
      const params = {
        contractAddress: bettingContractAddress,
        functionName: "deposit_bnb",
        abi: bettingABI,
        params: {
          leagueTitle: leagueValue, // league title: League A
          eventId: match.eventId, // event id: 0, 1, 2, 3 ...
          position: winTeam[1] // position: 0, 1, 2
        },
        msgValue: Moralis.Units.ETH(amount)
      }
      await contractProcessor.fetch({
        params,
        onSuccess: async (result) => {
          console.log('==>>', result);
          message.success({
            content: `Deposited on ${winTeam[0]} successfully!`,
            className: 'custom-class',
            style: {
              marginTop: '60px',
            },
          });
          setIsLoading(false)
          setShowBet(false)
          setTimeout(() => {
            getEventList(match.leagueId)
          }, 1000);
        },
        onError: (err) => {
          setIsLoading(false)
          console.log("onError=>", err);
        }
      });
    } catch (err) {
      setIsLoading(false)
      console.log('placeBetBNB catch error=>', err)
    }
  }

  const getOption = (token) => {
    // user wallet address
    const ops = {
      contractAddress: token === "MSWAP" ? mswapAddress : muftAddress,
      functionName: "approve",
      abi: genericErc20Abi, // mswap testnet contract ABI
      params: {
        spender: bettingContractAddress,
        amount: approveAmount
      },
    };
    return ops;
  }

  const getAllowanceOption = (token) => {
    // user wallet address
    const walletAddress = user.get("ethAddress")
    const ops = {
      contractAddress: token === "MSWAP" ? mswapAddress : muftAddress,
      functionName: "allowance",
      abi: genericErc20Abi, // mswap testnet contract ABI
      params: {
        owner: walletAddress,
        spender: bettingContractAddress
      },
    };
    return ops;
  }

  async function depositToken(val) {
    // deposit_token
    const params = {
      contractAddress: bettingContractAddress,
      functionName: "deposit_token",
      abi: bettingABI,
      params: {
        leagueTitle: leagueValue, // league title: League A
        eventId: match.eventId, // event id: 0, 1, 2, 3 ...
        token: BaseToken[val], // token: BNB(0), MSWAP(1), MUFT(2)
        amount: Moralis.Units.ETH(amount),
        position: winTeam[1] // position: 0, 1, 2
      },
    }
    await contractProcessor.fetch({
      params,
      onSuccess: async (result) => {
        console.log('placeBetToken res==>>', result);
        message.success({
          content: `Deposited on ${winTeam[0]} successfully!`,
          className: 'custom-class',
          style: {
            marginTop: '60px',
          },
        });
        setIsLoading(false)
        setShowBet(false)
        setTimeout(() => {
          getEventList(match.leagueId)
          getTokenBalances()
        }, 1000);
      },
      onError: (err) => {
        setIsLoading(false)
        console.log("onError=>", err);
      }
    });
  }

  async function placeBetToken(val) {
    try {
      setIsLoading(true)
      // check allowance
      await contractProcessor.fetch({
        params: getAllowanceOption(val),
        onSuccess: (res) => {
          // const mswapBalance = Moralis.Units.FromWei(res)
          console.log('checkAllowanceToken success', res);
          if (res.toString() === "0") {
            // approve_token
            contractProcessor.fetch({
              params: getOption(val),
              onSuccess: (res) => {
                // const mswapBalance = Moralis.Units.FromWei(res)
                console.log('approveTokenFunction success', res);
                depositToken(val)
              },
              onError: (error) => {
                console.log('approveTokenFunction err=>', error);
              },
            });
          } else {
            depositToken(val)
          }
        },
        onError: (error) => {
          console.log('checkAllowanceToken err=>', error);
        },
      });

    } catch (err) {
      setIsLoading(false)
      console.log('placeBetToken catch error=>', err)
    }
  }

  async function placeBet() {
    console.log('====================================');
    console.log(typeof (getBalance()));
    console.log(typeof (amount));
    console.log('====================================');
    if (!isAuthenticated && !account) {
      message.error({
        content: 'Please login to place bet',
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return;
    }
    if (parseFloat(amount) > parseFloat(getBalance())) {
      message.error({
        content: `You don't have enough ${value} to place this bet!`,
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return
    }
    if (amount < minAmount[value]) {
      message.error({
        content: `Minimum bet amount is ${minAmount[value]} ${value}`,
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
    } else {
      if (value === "BNB") placeBetBNB();
      if (value === "MSWAP" || value === "MUFT") placeBetToken(value);

    }
  }

  return (
    <>
      <div className="pricebet_card">
        <div className="pc_1">
          <img src={arrow} alt="arrow" onClick={() => setShowBet(false)} />
          <h1>
            Place your prediction on <b>{winTeam[0]}</b>
          </h1>
        </div>
        <hr />
        <div className="pc_2">
          <div>
            <p>Commit:</p> <p>{value ? value : "MUFT"}</p>
          </div>
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value > 0 ? e.target.value : 0)} />
          <h5>Balance: {getBalance()}</h5>
        </div>
        <div className="pc_3">
          <div>
            <div
              className={activeTab === "0" || activeTab === "1" || activeTab === "2" || activeTab === "3" ? "activeBet" : "nonActiveBet"}
              onClick={() => handleInputChange("0")}
            >
              <span>25%</span>
            </div>
            <div
              className={
                activeTab === "1" || activeTab === "2" || activeTab === "3"
                  ? "activeBet"
                  : "nonActiveBet"
              }
              onClick={() => handleInputChange("1")}
            >
              <span>50%</span>
            </div>
            <div
              className={
                activeTab === "2" || activeTab === "3"
                  ? "activeBet"
                  : "nonActiveBet"
              }
              onClick={() => handleInputChange("2")}
            >
              <span>75%</span>
            </div>
            <div
              className={activeTab === "3" ? "activeBet" : "nonActiveBet"}
              onClick={() => handleInputChange("3")}
            >
              <span>100%</span>
            </div>
          </div>
          <h5>Balance</h5>
        </div>
        <div className="p_3">
          <hr />
        </div>
        <div className="p_4">
          <button
            onClick={placeBet}
            style={{
              backgroundColor: (!isAuthenticated && !account) ? "gray" : "#ff2a3a"
            }}
          >Place your Prediction</button>
          <h5>
            You won’t be able to remove or change your position once you enter
            it.
          </h5>
        </div>
      </div>
    </>
  );
};

export default PriceBetCard;
