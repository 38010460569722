import React, { useState, useEffect } from "react";
import { Alert, Space, message, Modal } from "antd";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Text from "antd/lib/typography/Text";
import { useMoralis, useWeb3ExecuteFunction, useMoralisWeb3Api } from 'react-moralis'
import { genericErc20Abi, mswapAddress, muftAddress, bettingContractAddress, bettingABI, mswapPriceAPI, muftPriceAPI, bnbPriceAPI } from 'contracts/constants/index';
// import { useBnbPrice, useMswapPrice, useMuftPrice } from 'hooks/useTokenPrice';
import particlesConfig from 'contracts/constants/particlesConfigRed';
import BoxingPrizeAndRanks from "./BoxingPrizeAndRanks";
import { connectors } from "helpers/config";

const styles = {
    account: {
        height: "42px",
        padding: "0 15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        borderRadius: "12px",
        backgroundColor: "rgb(244, 244, 244)",
        cursor: "pointer",
    },
    text: {
        color: "#21BF96",
    },
    connector: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px 5px",
        cursor: "pointer",
    },
    icon: {
        alignSelf: "center",
        fill: "rgb(40, 13, 95)",
        flexShrink: "0",
        marginBottom: "8px",
        height: "30px",
    },
};

const BasketballPrediction = ({ isAuthModalVisible, setIsAuthModalVisible, setIsLoading, value }) => {
    const contractProcessor = useWeb3ExecuteFunction();
    const [showTabComp, setShowTabComp] = useState(false);
    const [leagueValue, setLeagueValue] = useState([]);
    const [isError, setIsError] = useState(false);
    const [leagueList, setLeagueList] = useState([])
    const [totalPayouts, setTotalPayouts] = useState(0)

    const { authenticate, isAuthenticated, web3, account } = useMoralis();

    const connectWallet = async (connectorId) => {
        try {
            if (!web3.givenProvider) {
                setIsError(true)
                setIsAuthModalVisible(false);
                return;
            }
            setIsLoading(true)
            await authenticate({ provider: connectorId });
            window.localStorage.setItem("connectorId", connectorId);
            setIsAuthModalVisible(false);
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.error(e);
            message.error("SignIn Failed")
        }
    }

    const particlesInit = async (main) => {
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
    };

    return (
        <div className="footbalPrediction_wrapper">
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={particlesConfig}
            />
            {(!isAuthenticated || !account) &&
                <Modal
                    visible={isAuthModalVisible}
                    footer={null}
                    onCancel={() => setIsAuthModalVisible(false)}
                    bodyStyle={{
                        padding: "15px",
                        fontSize: "17px",
                        fontWeight: "500",
                    }}
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    width="340px"
                >
                    <div
                        style={{
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "700",
                            fontSize: "20px",
                        }}
                    >
                        Connect Wallet
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        {connectors.map(({ title, icon, connectorId }, key) => (
                            <div
                                style={styles.connector}
                                key={key}
                                onClick={() => connectWallet(connectorId)}
                            >
                                <img src={icon} alt={title} style={styles.icon} />
                                <Text style={{ fontSize: "14px" }}>{title}</Text>
                            </div>
                        ))}
                    </div>
                </Modal>
            }

            {isError && <Alert
                message="Provider Error"
                description="No provider was found"
                type="error"
                action={
                    <Space direction="vertical" align="end">
                        <span onClick={() => setIsError(false)} style={{ cursor: "pointer" }}>
                            <CloseOutlined />
                        </span>
                    </Space>
                }
            />}

            <BoxingPrizeAndRanks
                setLeagueValue={setLeagueValue}
                setShowTabComp={setShowTabComp}
                leagueList={leagueList}
                getEventList={(id) => getEventList(id)}
                totalPayouts={totalPayouts}
            />
        </div>
    );
};

export default BasketballPrediction;
