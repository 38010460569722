import React, { useState, useEffect } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom"
import { useMoralis, useWeb3ExecuteFunction, useMoralisWeb3Api } from 'react-moralis'
import { bnbPriceAPI, bnbMuftLP, bnbMswapLP } from 'contracts/constants/index';
import "./Sidebar.css";
// assets
import homeIcon from "../../assets/icons/homeIcon.png";
import downArrow from "../../assets/icons/downArrow.png";
import exchangeIcon from "../../assets/icons/exchange.png";
import devilsIcon from "../../assets/icons/devils.png";
import redpoolIcon from "../../assets/icons/redpool.png";
import doomIcon from "../../assets/icons/doom.png";
import predictIcon from "../../assets/icons/predict.png";
import basketballIcon from "../../assets/icons/basketball.png";
import tennisIcon from "../../assets/icons/tennis.png";
import boxingIcon from "../../assets/icons/boxing.png";
import esportsIcon from "../../assets/icons/e-sports.png";
import gamesIcon from "../../assets/icons/games.png";
import snftIcon from "../../assets/icons/snft.png";
import auditsIcon from "../../assets/icons/audits.png";
import iloIcon from "../../assets/icons/ilo.png";
import listingIcon from "../../assets/icons/listing.png";
import bottom_logo from "../../assets/icons/bottom_logo.png";
import logo_circle1 from "../../assets/icons/logo_circle1.png";
import logo_circle2 from "../../assets/icons/logo_circle2.png";
import mswap from "../../assets/icons/mswap.png";
import muft from "../../assets/icons/muft.png";

const Sidebar = () => {
  const location = useLocation()
  const history = useHistory()
  const Web3Api = useMoralisWeb3Api();
  const { isWeb3Enabled, isAuthenticated, user, isInitialized, Moralis } = useMoralis();
  const [showSideBar, setShowSideBar] = useState(false);
  const [openExchangeDrpDwn, setOpenExchangeDrpDwn] = useState(false);
  const [openListingDrpDwn, setOpenListingDrpDwn] = useState(false);
  const [openPredictionDrpDwn, setOpenPredictionDrpDwn] = useState(true)
  const [mswapPrice, setMswapPrice] = useState(0)
  const [muftPrice, setMuftPrice] = useState(0)

  function commingSoon(e) {
    e.preventDefault();
    setShowSideBar(false)
  }

  const getPairPriceOption = (token) => {
    const options = {
      pair_address: token === "mswap" ? bnbMswapLP : bnbMuftLP,
      chain: "bsc",
    };
    return options
  }

  const getTokensPrice = () => {
    fetch(bnbPriceAPI)
      .then((res) => res.json())
      .then(async resJson => {
        const mswapReserves = await Web3Api.defi.getPairReserves(getPairPriceOption('mswap')); //{reserve0: '15634558164911470295257328', reserve1: '4781322433858722033'}
        const muftReserves = await Web3Api.defi.getPairReserves(getPairPriceOption('muft')); //{reserve0: '15634558164911470295257328', reserve1: '4781322433858722033'}
        const bnbPriceTemp = resJson[0].quotes.USD.price; // BNB price
        const mswapPriceTemp = bnbPriceTemp * Moralis.Units.FromWei(mswapReserves.reserve1) / Moralis.Units.FromWei(mswapReserves.reserve0);
        const muftPriceTemp = bnbPriceTemp * Moralis.Units.FromWei(muftReserves.reserve1) / Moralis.Units.FromWei(muftReserves.reserve0);
        console.log("sidebar tokens price=>", bnbPriceTemp, mswapPriceTemp, muftPriceTemp);
        setMswapPrice(mswapPriceTemp)
        setMuftPrice(muftPriceTemp)
      })
  }

  useEffect(() => {
    getTokensPrice()
  }, [isInitialized, user, isWeb3Enabled, isAuthenticated])

  function openExternalLink(e) {
    if (e === "mswap1") window.open("https://poocoin.app/tokens/0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b", "_blank");
    if (e === "mswap2") window.open("https://bscscan.com/token/0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B", "_blank");
    if (e === "muft1") window.open("https://poocoin.app/tokens/0x1d4a26a9d980afc2287dca15eb896a26384bac7c", "_blank");
    if (e === "muft2") window.open("https://bscscan.com/token/0x1D4a26a9D980AFC2287Dca15eb896A26384bAc7c", "_blank");
  }

  return (
    <>
      <div className="header_wrap">
        <div className="nav_menu" onClick={() => setShowSideBar(!showSideBar)}>
          {showSideBar ? (
            <svg
              viewBox="0 0 24 24"
              width="24px"
              color="rgb(255, 42, 58)"
              xmlns="http://www.w3.org/2000/svg"
              className="sc-bdnxRM ksaFaP"
            >
              <path d="M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z" />
            </svg>
          ) : (
            <svg
              viewBox="0 0 24 24"
              width="24px"
              color="rgb(255, 42, 58)"
              xmlns="http://www.w3.org/2000/svg"
              className="sc-bdnxRM ksaFaP"
            >
              <path d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z" />
            </svg>
          )}
        </div>
        <div className={`sidebar ${showSideBar ? "active_sidebar" : ""}`}>
          <div className="menu_list">
            <a href="https://muftswap.exchange" className="menu_item">
              <img src={homeIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">Home</span>
            </a>
            <div className="menu_item" onClick={() => setShowSideBar(false)}>
              <div className="menu_drpdwn">
                <div onClick={() => setOpenExchangeDrpDwn(!openExchangeDrpDwn)}>
                  <div className="md_1">
                    <img src={exchangeIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Exchange</span>
                  </div>
                  <img
                    className="dwn_arrow"
                    style={{
                      transform: openExchangeDrpDwn ? "rotate(-180deg)" : "",
                    }}
                    src={downArrow}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
            <div
              className={`menu_drp_item ${openExchangeDrpDwn ? "showDrp" : ""}`}
            >
              {openExchangeDrpDwn ? (
                <>
                  <a href="https://muftswap.exchange/swap" className="menu_item">
                    <span className="sidebar-menu-title-fs18">Swap</span>
                  </a>
                  <a href="https://muftswap.exchange/liquidity" className="menu_item">
                    <span className="sidebar-menu-title-fs18">Liquidity</span>
                  </a>
                </>
              ) : (
                ""
              )}
            </div>
            <a href="https://muftswap.exchange/farms" className="menu_item">
              <img src={devilsIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">Devil’s Farms</span>
            </a>
            <a href="https://muftswap.exchange/pools" className="menu_item">
              <img src={redpoolIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">Red Pools</span>
            </a>
            <a href="https://muftswap.exchange/doompit" className="menu_item">
              <img src={doomIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">Doom’s Pit</span>
            </a>
            <div className="menu_item" onClick={() => setShowSideBar(false)}
              style={{
                backgroundColor: location.pathname === '/' ? '#C70101' : 'transparent',
                boxShadow: location.pathname === '/' ? 'rgb(199 1 1 / 50%) 0px 23px 34px -19px' : 'none'
              }}
            >
              <div className="menu_drpdwn">
                <div onClick={() => {
                  history.push("/");
                  setOpenPredictionDrpDwn(!openPredictionDrpDwn)
                }}>
                  <div className="md_1">
                    <img src={predictIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Sports Predictions</span>
                  </div>
                  <img
                    className="dwn_arrow"
                    style={{
                      transform: openPredictionDrpDwn ? "rotate(-180deg)" : "",
                    }}
                    src={downArrow}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
            <div className={`menu_drp_item ${openPredictionDrpDwn ? "showDrp" : ""}`}>
              {openPredictionDrpDwn ? (
                <>
                  <NavLink
                    onClick={() => setShowSideBar(false)}
                    to="/football"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                    style={{
                      backgroundColor: location.pathname === '/football' ? '#C70101' : 'transparent',
                      boxShadow: location.pathname === '/football' ? 'rgb(199 1 1 / 50%) 0px 23px 34px -19px' : 'none'
                    }}
                  >
                    <img src={gamesIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Football</span>
                  </NavLink>
                  <NavLink
                    onClick={() => setShowSideBar(false)}
                    to="/basketball"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                    style={{
                      backgroundColor: location.pathname === '/basketball' ? '#C70101' : 'transparent',
                      boxShadow: location.pathname === '/basketball' ? 'rgb(199 1 1 / 50%) 0px 23px 34px -19px' : 'none'
                    }}
                  >
                    <img src={basketballIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Basketball</span>
                  </NavLink>
                  <NavLink
                    onClick={() => setShowSideBar(false)}
                    to="/tennis"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                    style={{
                      backgroundColor: location.pathname === '/tennis' ? '#C70101' : 'transparent',
                      boxShadow: location.pathname === '/tennis' ? 'rgb(199 1 1 / 50%) 0px 23px 34px -19px' : 'none'
                    }}
                  >
                    <img src={tennisIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Tennis</span>
                  </NavLink>
                  <NavLink
                    onClick={() => setShowSideBar(false)}
                    to="/boxing"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                    style={{
                      backgroundColor: location.pathname === '/boxing' ? '#C70101' : 'transparent',
                      boxShadow: location.pathname === '/boxing' ? 'rgb(199 1 1 / 50%) 0px 23px 34px -19px' : 'none'
                    }}
                  >
                    <img src={boxingIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Boxing & UFC</span>
                  </NavLink>
                  <NavLink
                    onClick={() => setShowSideBar(false)}
                    to="/esports"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                    style={{
                      backgroundColor: location.pathname === '/esports' ? '#C70101' : 'transparent',
                      boxShadow: location.pathname === '/esports' ? 'rgb(199 1 1 / 50%) 0px 23px 34px -19px' : 'none'
                    }}
                  >
                    <img src={esportsIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">E-Sports</span>
                  </NavLink>
                </>
              ) : (
                ""
              )}
            </div>


            <a href="https://muftswap.exchange/game" className="menu_item">
              <img src={gamesIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">Football Games</span>
            </a>
            <a href="https://muftswap.exchange/snft" className="menu_item">
              <img src={snftIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">SNFT Marketplace</span>
            </a>
            <a href="https://muftswap.exchange/audit" className="menu_item">
              <img src={auditsIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">Audits</span>
            </a>
            <a href="https://ilo.muftswap.exchange/" className="menu_item">
              <img src={iloIcon} alt="icon" />
              <span className="sidebar-menu-title-fs18">ILO</span>
            </a>

            <div className="menu_item" onClick={() => setShowSideBar(false)}>
              <div className="menu_drpdwn">
                <div onClick={() => setOpenListingDrpDwn(!openListingDrpDwn)}>
                  <div className="md_1">
                    <img src={listingIcon} alt="icon" />
                    <span className="sidebar-menu-title-fs18">Listings</span>
                  </div>
                  <img
                    className="dwn_arrow"
                    style={{ transform: openListingDrpDwn ? "rotate(-180deg)" : "" }}
                    src={downArrow}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
            <div className={`menu_drp_item ${openListingDrpDwn ? "showDrp2" : ""}`}>
              {openListingDrpDwn ? (
                <>
                  <a href="https://coinmarketcap.com/currencies/muftswap/" className="menu_item" target="_blank">
                    <span className="sidebar-menu-title-fs18">CoinMarketCap</span>
                  </a>
                  <a href="https://coinmooner.com/coin/6924" className="menu_item" target="_blank">
                    <span className="sidebar-menu-title-fs18">CoinMooner </span>
                  </a>
                  <NavLink
                    onClick={commingSoon}
                    to="/coinGecko"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                  >
                    <span className="sidebar-menu-title-fs18">CoinGecko </span>
                  </NavLink>
                  <NavLink
                    onClick={commingSoon}
                    to="/ftx"
                    className={({ isActive }) =>
                      isActive ? "menu_item active" : "menu_item inactive"
                    }
                  >
                    <span className="sidebar-menu-title-fs18">FTX</span>
                  </NavLink>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="divider"></div>
          <div className="sidebar_bottom">
            <img src={bottom_logo} alt="" />
            <div className="sb_content">
              <div>
                <span>MSWAP</span>
                <img src={mswap} alt="..." onClick={() => openExternalLink('mswap1')} />
              </div>
              <div>
                <span>${mswapPrice.toFixed(9)}</span>
                <img src={logo_circle2} alt="..." onClick={() => openExternalLink('mswap2')} />
              </div>
            </div>
            <div className="sb_content">
              <div>
                <span>MUFT</span>
                <img src={muft} alt="..." onClick={() => openExternalLink('muft1')} />
              </div>
              <div>
                <span>${muftPrice.toFixed(9)}</span>
                <img src={logo_circle2} alt="..." onClick={() => openExternalLink('muft2')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
