import React, { useEffect, useState } from 'react'
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis'
import { Menu, Layout, Button, Dropdown, Alert, Space, Drawer, message, Modal, Row, Col } from "antd";
import { useLocation } from 'react-router-dom'
import { bettingContractAddress, bettingABI, statusArray, Position } from 'contracts/constants/index';
import showMessage from 'components/Message';
import './EventList.css'

const EventList = ({ setIsLoading }) => {
  const location = useLocation()
  // const useQuery = () => new URLSearchParams(useLocation().search);
  const contractProcessor = useWeb3ExecuteFunction();
  const [leagueItem, setLeagueItem] = useState({})
  const [teamName1, setTeamName1] = useState("")
  const [teamName2, setTeamName2] = useState("")
  const [eventList, setEventList] = useState([])
  // const [startTime, setStartTime] = useState([])
  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, logout, user, isInitialized, Moralis } = useMoralis();

  async function getEventList(id) {
    console.log('====================================');
    console.log(id);
    console.log(isInitialized);
    console.log('====================================');
    if (!isInitialized) return;
    try {
      setIsLoading(true)
      const params = {
        leagueId: id
      }
      const events = await Moralis.Cloud.run("getEvents", params);
      console.log('events1=>', events)
      setEventList(events)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log('getEventList err=>', err)
    }
  }

  async function createEvent() {
    try {
      setIsLoading(true)
      // create_league
      const params = {
        contractAddress: bettingContractAddress,
        functionName: "create_bet",
        abi: bettingABI,
        params: {
          league_title: leagueItem.title, // NOTE: title 
          bet_title: `${teamName1} VS ${teamName2}`, // NOTE: title 
        }
      }
      await contractProcessor.fetch({
        params,
        onSuccess: async (result) => {
          console.log('==>>', result);
          showMessage("Event created successfully", "success")
          getEventList(leagueItem.leagueId)
        },
        onError: (err) => {
          setIsLoading(false)
          console.log("onError=>", err);
        }
      });
    } catch (err) {
      setIsLoading(false)
      console.log('catch error=>', err)
    }
  }

  async function reportResult(pos, eId, idx) {
    console.log(leagueItem.title)
    console.log(pos)
    console.log(eId)
    if (pos === Position.LIVE && eventList[idx].startTime === "") {
      showMessage("Please set start time first", "error")
      return;
    }
    if (pos === Position.outcomeA && eventList[idx].scoreA <= eventList[idx].scoreB) {
      showMessage("You entered incorrect score!", "error")
      return;
    }
    if (pos === Position.outcomeB && eventList[idx].scoreA >= eventList[idx].scoreB) {
      showMessage("You entered incorrect score!", "error")
      return;
    }
    if (pos === Position.outcomeC && eventList[idx].scoreA !== eventList[idx].scoreB) {
      showMessage("You entered incorrect score!", "error")
      return;
    }
    try {
      setIsLoading(true)
      // create_league
      const params = {
        contractAddress: bettingContractAddress,
        functionName: "report_result",
        abi: bettingABI,
        params: {
          leagueTitle: leagueItem.title, // NOTE: league title 
          eventId: eId, // NOTE: eventId
          result: pos, // NOTE: title 
        }
      }
      await contractProcessor.fetch({
        params,
        onSuccess: async (result) => {
          console.log('==>>', result);
          showMessage("Event reported successfully", "success")
          setTimeout(() => {
            getEventList(leagueItem.leagueId)
          }, 1000);
        },
        onError: (err) => {
          setIsLoading(false)
          console.log("reportResult onError=>", err);
        }
      });
    } catch (err) {
      setIsLoading(false)
      console.log('reportResult catch error=>', err)
    }
  }

  async function handleCreateEvent() {
    if (teamName1 === "" || teamName2 === "") alert("Please enter team name")
    else {
      if (!isAuthenticated) {
        await authenticate()
        createEvent()
      } else createEvent()
    }
  }

  function handleStartTimeChange(e, i) {
    console.log('e.target.value', e.target.value);
    const eventListCopy = [...eventList]
    eventListCopy[i].startTime = getUTCTimeStamp(e.target.value)
    console.log('eventListCopy', eventListCopy);
    setEventList(eventListCopy)
  }

  function handleScoreAChange(e, i) {
    if (e.target.value < 0) return;
    const eventListCopy = [...eventList]
    eventListCopy[i].scoreA = e.target.value
    setEventList(eventListCopy)
  }

  function handleScoreBChange(e, i) {
    if (e.target.value < 0) return;
    const eventListCopy = [...eventList]
    eventListCopy[i].scoreB = e.target.value
    setEventList(eventListCopy)
  }

  function getUTCTimeStamp(sTime) {
    let date = new Date(sTime);
    // date.setDate(date.getDate() + parseFloat(days));
    // date.setHours(date.getHours() + parseFloat(days) * 24);
    date = date.toUTCString()

    const utcDate = new Date(date);
    const seconds = utcDate.getTime() / 1000;
    // console.log("Seconds:", seconds, days, parseFloat(days) * 24)
    return parseInt(seconds).toString()
  }

  function getUTCTime(timestamp) {
    const milliseconds = parseInt(timestamp) * 1000;
    return new Date(new Date(milliseconds).toUTCString()).toISOString().slice(0, -1);
  }

  async function updateStartTime(item, i) {
    if (!eventList[i].startTime) {
      alert('Please enter start time')
    } else {
      if (confirm('Are you sure to update start time?')) {
        try {
          setIsLoading(true)
          const params = {
            leagueId: item.leagueId, // NOTE: league id 
            eventId: item.eventId, // NOTE: eventId
            startTime: eventList[i].startTime, // NOTE: UTC time stamp 
          }
          await Moralis.Cloud.run("updateEventStartTime", params)
          message.success({
            content: 'Event start time updated successfully',
            className: 'custom-class',
            style: {
              marginTop: '60px',
            },
          });
          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          console.log('startTime update catch error=>', err)
        }
      }
    }
  }

  function getUTCTimeValue(i) {
    if (eventList[i].startTime !== "") {
      return getUTCTime(eventList[i].startTime)
    } else return ''
  }

  async function handleResultScore(item, i) {
    if (confirm('Are you sure to update score?')) {
      try {
        setIsLoading(true)
        const params = {
          scoreA: eventList[i].scoreA,
          scoreB: eventList[i].scoreB,
          leagueId: item.leagueId, // NOTE: league id 
          eventId: item.eventId, // NOTE: eventId
        }
        await Moralis.Cloud.run("updateResultScore", params)
        message.success({
          content: 'Event score updated successfully',
          className: 'custom-class',
          style: {
            marginTop: '60px',
          },
        });
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log('Event score update catch error=>', err)
      }
    }
  }

  function getEventStatus(eventStatus) {
    switch (eventStatus) {
      case Position.LIVE:
        return "green"
      case Position.STOP:
        return "red"
      case Position.CREATED:
        return "gray"
      case Position.CANCEL:
        return "black"
      case Position.outcomeA:
        return "blue"
      case Position.outcomeB:
        return "blue"
      case Position.outcomeC:
        return "blue"
      default:
        break;
    }
  }

  useEffect(() => {
    console.log('location=>', location)
    console.log('location.pathname=>', location.state.leagueItem)
    const currentLeague = location.state.leagueItem
    setLeagueItem(currentLeague)
    getEventList(currentLeague.leagueId)
  }, [location, isInitialized])

  return (
    <div className="admin-container">
      <h1>Event List</h1>
      <div className="flex-direction-row">
        <input value={teamName1} type="text" onChange={(e) => setTeamName1(e.target.value)} />
        <p> VS </p>
        <input value={teamName2} type="text" onChange={(e) => setTeamName2(e.target.value)} />
        <button onClick={() => handleCreateEvent()}>Create Event for {leagueItem.title}</button>
      </div>
      {/* <p>={getUTCTimeValue(1)}=</p> */}
      <div className="flex-direction-row league-group">
        {
          eventList.map((item, i) => (
            <div key={item.objectId} className="league-event">
              <p onClick={() => console.log('==>>', item)}>{item.eventTitle} (ID: {item.eventId})</p>
              <p>({item.scoreA} : {item.scoreB})</p>
              <p style={{
                padding: "0 10px",
                backgroundColor: getEventStatus(item.result.toString())
              }}>({statusArray[item.result]})</p>
              <div className="score-area">
                <input type="number" className="event-score-input" value={eventList[i].scoreA} onChange={(e) => handleScoreAChange(e, i)} />
                <input type="number" className="event-score-input" value={eventList[i].scoreB} onChange={(e) => handleScoreBChange(e, i)} />
                <button onClick={() => handleResultScore(item, i)}>END</button>
              </div>
              <div>
                <button onClick={() => reportResult(Position.outcomeA, item.eventId, i)}>Team1</button>
                <button onClick={() => reportResult(Position.outcomeB, item.eventId, i)}>Team2</button>
                <button onClick={() => reportResult(Position.outcomeC, item.eventId, i)}>DRAW</button>
              </div>
              <div>
                <button onClick={() => reportResult(Position.LIVE, item.eventId, i)}>LIVE</button>
                <button onClick={() => reportResult(Position.STOP, item.eventId, i)}>STOP</button>
                <button onClick={() => reportResult(Position.CANCEL, item.eventId, i)}>CANCEL</button>
              </div>
              <p>Start Time (UTC)</p>
              {/* <input type="datetime-local" id="start-time" name="start-time" value={'2022-04-23T19:00'} onChange={(e) => handleStartTimeChange(e, i)} /> */}
              <input type="datetime-local" id="start-time" name="start-time" value={getUTCTimeValue(i)} onChange={(e) => handleStartTimeChange(e, i)} />
              <button onClick={() => updateStartTime(item, i)}>UPDATE</button>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default EventList
