import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useMoralis } from "react-moralis";
import "./PredictionCards.css";
import PriceBetCard from "./PriceBetCard";
import { BaseToken, Position } from "contracts/constants/index";
import bnb from "../../../assets/icons/bnb.png";
import mswap from "../../../assets/icons/mswap.png";
import muft from "../../../assets/icons/muft.png";

const PredictionCards = ({ value, match, balanceArray, setIsLoading, leagueValue, setIsAuthModalVisible, getEventList, getTokenBalances }) => {
  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, Moralis, user, isInitialized } = useMoralis();
  const [showBet, setShowBet] = useState(false);
  const [winTeam, setWinTeam] = useState([])

  useEffect(() => {
    // console.log('====================================');
    // console.log(match);
    // console.log('====================================');
  }, [])

  const getUTCTime = (timestamp) => {
    const milliseconds = parseInt(timestamp) * 1000;
    return timestamp !== "" ? `${new Date(new Date(milliseconds).toUTCString()).toISOString().slice(0, -8).replace("T", " ")} (UTC)` : "---"
  }

  const getTotalPrize = () => {
    switch (value) {
      case "BNB":
        return parseFloat(Moralis.Units.FromWei(match.totalPrizeBnb)).toFixed(4)
      case "MSWAP":
        return parseFloat(Moralis.Units.FromWei(match.totalPrizeMswap)).toFixed(2)
      case "MUFT":
        return parseFloat(Moralis.Units.FromWei(match.totalPrizeMuft)).toFixed(2)
      default:
        break;
    }
  }

  const getAPR = (team) => {
    if (value === "BNB") {
      switch (team) {
        case 1:
          return match.poolSizeBnbA === 0 ? 0 : match.totalPrizeBnb / match.poolSizeBnbA
        case 2:
          return match.poolSizeBnbC === 0 ? 0 : match.totalPrizeBnb / match.poolSizeBnbC
        case 3:
          return match.poolSizeBnbB === 0 ? 0 : match.totalPrizeBnb / match.poolSizeBnbB
        default:
          break;
      }
    } else if (value === "MSWAP") {
      switch (team) {
        case 1:
          return match.poolSizeMswapA === 0 ? 0 : match.totalPrizeMswap / match.poolSizeMswapA
        case 2:
          return match.poolSizeMswapC === 0 ? 0 : match.totalPrizeMswap / match.poolSizeMswapC
        case 3:
          return match.poolSizeMswapB === 0 ? 0 : match.totalPrizeMswap / match.poolSizeMswapB
        default:
          break;
      }
    } else if (value === "MUFT") {
      switch (team) {
        case 1:
          return match.poolSizeMuftA === 0 ? 0 : match.totalPrizeMuft / match.poolSizeMuftA
        case 2:
          return match.poolSizeMuftC === 0 ? 0 : match.totalPrizeMuft / match.poolSizeMuftC
        case 3:
          return match.poolSizeMuftB === 0 ? 0 : match.totalPrizeMuft / match.poolSizeMuftB
        default:
          break;
      }
    }
  }

  const teamData = [
    { id: 1, num: "1", value: getAPR(1), betNumber: "0" },
    { id: 2, num: "X", value: getAPR(2), betNumber: "2" },
    { id: 3, num: "2", value: getAPR(3), betNumber: "1" },
  ];

  const selectTeam = (id) => {
    if (match.result.toString() !== Position.LIVE) {
      message.error({
        content: 'Please wait for the match to start',
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return;
    }
    if (parseInt(match.position) > -1) {
      message.error({
        content: 'You have already bet on this match',
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return;
    }
    setShowBet(true)
    getTokenBalances()
    if (id === 1) setWinTeam([match.eventTitle.split('VS')[0], Position.outcomeA])
    if (id === 2) setWinTeam(["DRAW", Position.outcomeC])
    if (id === 3) setWinTeam([match.eventTitle.split('VS')[1], Position.outcomeB])
  }

  function getBetStatus(team) {
    if (team === match.position) return true
    else return false
  }

  function getBetedImage() {
    switch (match.baseToken) {
      case BaseToken.BNB:
        return bnb
      case BaseToken.MSWAP:
        return mswap
      case BaseToken.MUFT:
        return muft
      default:
        break;
    }
  }

  return (
    <>
      <div className={!showBet ? "prediction_card" : 'prediction_card flip_card'}>
        {!showBet ? (
          <>
            <h2>{match.eventTitle}</h2>
            <hr />
            <div className="p_1">
              <div>
                <strong>Start Time:</strong>
                <p>{getUTCTime(match.startTime)}</p>
              </div>
              <div>
                <strong>Prize Pool:</strong>
                <p>
                  {getTotalPrize()} {value}
                  {/* {match.totalPrizeBnb} {value} */}
                </p>
              </div>
            </div>
            <div className="p_2">
              {teamData.map((d) => {
                return (
                  <div key={d.id} className="p2_div">
                    <div
                      onClick={() => selectTeam(d.id)}
                      style={{
                        backgroundColor: match.result.toString() !== Position.LIVE ? "gray" : "#ff2a3a"
                      }}
                    >
                      <span>{d.num}</span>
                    </div>
                    <div>
                      <p>{d.value.toFixed(2)}X</p>
                    </div>
                    {
                      (isAuthenticated || account) && getBetStatus(d.betNumber) &&
                      <div id="deposited-team">
                        <img src={getBetedImage()} alt="..." />
                        {/* <h5 style={{ color: "#ff2a3a" }}>BET</h5> */}
                      </div>
                    }
                  </div>
                );
              })}
            </div>
            {
              (!isAuthenticated && !account) &&
              <>
                <div className="p_3">
                  <hr />
                </div>
                <div className="p_4">
                  <button onClick={() => setIsAuthModalVisible(true)}>Connect Wallet</button>
                </div>
              </>
            }
          </>
        ) : (
          <PriceBetCard
            value={value}
            setShowBet={setShowBet}
            winTeam={winTeam}
            balanceArray={balanceArray}
            setIsLoading={setIsLoading}
            leagueValue={leagueValue}
            match={match}
            getEventList={getEventList}
            getTokenBalances={getTokenBalances}
          />
        )}
      </div>
    </>
  );
};

export default PredictionCards;
