import mswap from "../assets/icons/mswap.png";
import muft from "../assets/icons/muft.png";
import bnb from "../assets/icons/bnb.png";

export const data = [
  {
    img: bnb,
    name: "BNB",
  },
  {
    img: mswap,
    name: "MSWAP",
  },
  {
    img: muft,
    name: "MUFT",
  }
];