import React, { useLayoutEffect } from "react";
import { useHistory } from 'react-router-dom'
// assets
import bootballBanner from "../../assets/images/bootballBanner.jpg";
import arrowIcon from "../../assets/icons/rightArrow.png";
// data
import { PrizeData } from "../../data/PrizeData";

const PrizesAndRanks = ({ setShowTabComp, getEventList, leagueList, setLeagueValue, totalPayouts }) => {
  const history = useHistory();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="fpw_banner">
        <div className="banner-img-container">
          <img src={bootballBanner} alt="bootballBanner" />
        </div>
        <div className="fpw_content sub-sports-title">
          <h1>
            Football Predictions
          </h1>
          {/* <h4 style={{ color: 'white' }} className="fpw_description">Sports Predictions (SP) Predict-to-Earn is a decentralised protocol that aims to bring further innovation in the crypto-sports gaming field</h4> */}
        </div>
        <div className="total-payouts">
          <p>
            Total Pay-outs: <b>{parseInt(totalPayouts)}$</b>
          </p>
        </div>
      </div>
      <div className="fqw_content">
        <h2>Rewards and Ranks</h2>
        <div className="fqw_wrap">
          <div className="fqw_box b_1">
            <h5>Total Prizes</h5>
            <div className="b1_content">
              <strong>Over ${parseInt(totalPayouts)}</strong>
              <p>in BNB, MSWAP, MUFT won so far.</p>
            </div>
          </div>
          <div className="fqw_box b_2">
            <h5>Top Predictors</h5>
            <p style={{ zIndex: 10, position: 'absolute', top: '40%', color: 'white', left: '20%', fontSize: '30px' }}>Coming Soon</p>
            <div className="b_2_content">
              <ul>
                {PrizeData.map((d) => {
                  return (
                    <li key={d.id}>
                      <div>
                        <span>#{d.id}</span> <p>{d.value}</p>
                      </div>
                      <div>
                        <p>
                          {d.price} {d.type}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <h2>All Competitions</h2>
        <div className="league_wrap">
          {leagueList.map((item, index) => {
            return (
              <div
                key={index}
                className="lw_box"
                onClick={() => {
                  setShowTabComp(true);
                  getEventList(item.leagueId)
                  setLeagueValue([item.leagueId, item.title])
                }}
              >
                <div>
                  {/* <img className={item.none} src={item.img} alt={item.name} /> */}
                  <strong>{item.title}</strong>
                </div>
                <img src={arrowIcon} alt="arrow" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PrizesAndRanks;
