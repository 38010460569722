import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Menu, Layout, Button, Dropdown, Alert, Space, Drawer, message, Modal, Row, Col } from "antd";
import { DownOutlined, CloseOutlined, WalletOutlined } from '@ant-design/icons';
import Text from "antd/lib/typography/Text";
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis'
import { connectors } from "helpers/config";
import { contractAddress, leagueABI, bettingABI, bettingContractAddress } from 'contracts/constants/index';
import showMessage from 'components/Message';
import './MswapAdmin.css'
import 'antd/dist/antd.css';

const styles = {
    account: {
        height: "42px",
        padding: "0 15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        borderRadius: "12px",
        backgroundColor: "rgb(244, 244, 244)",
        cursor: "pointer",
    },
    text: {
        color: "#21BF96",
    },
    connector: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px 5px",
        cursor: "pointer",
    },
    icon: {
        alignSelf: "center",
        fill: "rgb(40, 13, 95)",
        flexShrink: "0",
        marginBottom: "8px",
        height: "30px",
    },
};

const MswapAdmin = ({ isAuthModalVisible, setIsAuthModalVisible, setIsLoading }) => {
    const history = useHistory()
    const contractProcessor = useWeb3ExecuteFunction();
    const [leagueName, setLeagueName] = useState("")
    const [isError, setIsError] = useState(false);
    const [leagueList, setLeagueList] = useState([])

    const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, logout, user, isInitialized, Moralis } = useMoralis();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const connectWallet = async (connectorId) => {
        try {
            if (!web3.givenProvider) {
                setIsError(true)
                setIsAuthModalVisible(false);
                return;
            }
            setIsLoading(true)
            await authenticate({ provider: connectorId });
            window.localStorage.setItem("connectorId", connectorId);
            setIsAuthModalVisible(false);
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.error(e);
            message.error("SignIn Failed")
        }
    }

    async function getLeagues() {
        if (!isInitialized) return;
        try {
            setIsLoading(true)
            const leagues = await Moralis.Cloud.run("getLeagues");
            console.log('leagues=>', leagues)
            setIsLoading(false)
            setLeagueList(leagues)
        } catch (err) {
            console.log('getLeagues catch err=>', err)
        }
    }

    async function createLeague() {
        try {
            setIsLoading(true)
            // create_league
            const params = {
                contractAddress,
                functionName: "create_league",
                abi: leagueABI,
                params: {
                    title: leagueName, // NOTE: title 
                }
            }
            await contractProcessor.fetch({
                params,
                onSuccess: async (result) => {
                    console.log('==>>', result);
                    showMessage('League created successfully', 'success');
                    getLeagues()
                },
                onError: (err) => {
                    setIsLoading(false)
                    console.log("onError=>", err);
                }
            });
        } catch (err) {
            setIsLoading(false)
            console.log('catch error=>', err)
        }
    }

    async function removeLeague(ttl) {
        try {
            setIsLoading(true)
            // create_league
            const params = {
                contractAddress,
                functionName: "remove_league",
                abi: leagueABI,
                params: {
                    title: ttl, // NOTE: title 
                }
            }
            await contractProcessor.fetch({
                params,
                onSuccess: async (result) => {
                    console.log('================>>', result);
                    showMessage('League removed successfully', 'success');
                    getLeagues()
                },
                onError: (err) => {
                    setIsLoading(false)
                    console.log("onError=>", err);
                }
            });
        } catch (err) {
            setIsLoading(false)
            console.log('catch error=>', err)
        }
    }

    async function handleCreateLeague() {
        if (leagueName === "") alert("Please enter league name")
        else {
            if (!isAuthenticated) {
                await authenticate()
                createLeague()
            } else createLeague()
        }
        console.log('created ');
    }

    async function handleRemoveLeague(leagueTitle) {
        if (confirm("Are you sure to remove this league?")) {
            if (!isAuthenticated) {
                await authenticate()
                removeLeague(leagueTitle)
            } else removeLeague(leagueTitle)
        }
    }

    function goToEventList(leagueItem) {
        console.log(leagueItem)
        history.push(`/admin/${leagueItem.leagueId}`, {
            leagueItem
        })
    }

    async function claimTreasury() {
        if (!isAuthenticated) {
            setIsAuthModalVisible(true)
        } else {
            try {
                setIsLoading(true)
                // claim_treasury
                const params = {
                    contractAddress: bettingContractAddress,
                    functionName: "claimTreasury",
                    abi: bettingABI,
                    params: {}
                }
                await contractProcessor.fetch({
                    params,
                    onSuccess: async (result) => {
                        console.log('================>>', result);
                        showMessage('Treasury claimed successfully', 'success');
                    },
                    onError: (err) => {
                        setIsLoading(false)
                        console.log("Treasury claim onError=>", err);
                    }
                });
            } catch (err) {
                setIsLoading(false)
                console.log('catch error=>', err)
            }
        }
    }

    useEffect(() => {
        getLeagues()
    }, [isInitialized])

    return (
        <div className="admin-container">
            <div className="metamask-setup">
                {isError && <Alert
                    message="Provider Error"
                    description="No provider was found"
                    type="error"
                    action={
                        <Space direction="vertical" align="end">
                            <span onClick={() => setIsError(false)} style={{ cursor: "pointer" }}>
                                <CloseOutlined />
                            </span>
                        </Space>
                    }
                />}
            </div>
            {(!isAuthenticated || !account) &&
                <Modal
                    visible={isAuthModalVisible}
                    footer={null}
                    onCancel={() => setIsAuthModalVisible(false)}
                    bodyStyle={{
                        padding: "15px",
                        fontSize: "17px",
                        fontWeight: "500",
                    }}
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    width="340px"
                >
                    <div
                        style={{
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "700",
                            fontSize: "20px",
                        }}
                    >
                        Connect Wallet
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        {connectors.map(({ title, icon, connectorId }, key) => (
                            <div
                                style={styles.connector}
                                key={key}
                                onClick={() => connectWallet(connectorId)}
                            >
                                <img src={icon} alt={title} style={styles.icon} />
                                <Text style={{ fontSize: "14px" }}>{title}</Text>
                            </div>
                        ))}
                    </div>
                </Modal>
            }
            <h1>All Competitions</h1>
            <button onClick={claimTreasury}>Claim treasury</button>
            <div className="flex-direction-row">
                <input value={leagueName} type="text" onChange={(e) => setLeagueName(e.target.value)} />
                <button onClick={handleCreateLeague}>Create League</button>
            </div>
            <div className="flex-direction-row league-group">
                {
                    leagueList.map((item) => (
                        <div key={item.objectId} className="league">
                            <div onClick={() => goToEventList(item)} className="league-title-area">{item.title}</div>
                            <div onClick={() => handleRemoveLeague(item.title)} className="remove-btn-area">Delete</div>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}

export default MswapAdmin
