import React, { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";
import logo from "../../assets/icons/logo.svg";
import downArrow from "../../assets/icons/downArrow.png";
import wallet from "../../assets/icons/wallet.png";

const TopBar = ({ data, value, setValue, setIsAuthModalVisible }) => {
  const [showDrp, setShowDrp] = useState(false);
  const [walletAddress, setWalletAddress] = useState("")
  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, logout, user, isInitialized } = useMoralis();

  useEffect(() => {
    if (!isInitialized || !isAuthenticated || !user) return;
    // console.log('====================================');
    // console.log('TopBar account=>', account)
    // console.log('TopBar user=>', user)
    // console.log('TopBar isInitialized=>', isInitialized)
    // console.log('TopBar isWeb3Enabled=>', isWeb3Enabled)
    // console.log('TopBar isAuthenticated=>', isAuthenticated)
    // console.log('TopBar isWeb3EnableLoading=>', isWeb3EnableLoading)
    // console.log('TopBar web3=>', web3)
    // console.log('TopBar user=>', user.get("ethAddress"))
    // console.log('====================================');

    const walletAddressTemp = user.get("ethAddress")
    setWalletAddress(walletAddressTemp)
  }, [isAuthenticated, user])

  const handleWallet = () => {
    if (!isAuthenticated && !account) {
      console.log('first')
      setIsAuthModalVisible()
    } else {
      console.log('second')
      logout()
    }
  }

  const displayAddress = (addr) => {
    const stringToBeCut = addr.slice(4, walletAddress.length - 4)
    return walletAddress.replace(stringToBeCut, " . . . ")
  }

  return (
    <div className="top_bar">
      <div className="c_container">
        <div className="nav_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav_btns">
          <div className="nb_1">
            <div onClick={() => setShowDrp(!showDrp)}>
              <img
                src={value[0]}
                alt="..."
              />
              <span>{value[1]}</span>
              <img src={downArrow} alt="" />
            </div>
            {showDrp ? (
              <div className="drp_down">
                {data.map((d, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setValue([d.img, d.name]);
                        setShowDrp(!showDrp);
                      }}
                    >
                      <img src={d.img} alt="..." />
                      <span>{d.name}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nb_2">
            <button onClick={handleWallet}>
              <img src={wallet} alt="..." />
              {(!isAuthenticated && !account) ? 'Connect Wallet' : displayAddress(walletAddress)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
