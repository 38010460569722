export const PrizeData = [
    {
        id: 1,
        value: "0x4300sd....93u4389",
        price: 16.32,
        type: "BNB"
    },
    {
        id: 2,
        value: "0x4300sd....93u4389",
        price: 16.32,
        type: "BNB"
    },
    {
        id: 3,
        value: "0x4300sd....93u4389",
        price: 16.32,
        type: "BNB"
    },
    {
        id: 4,
        value: "0x4300sd....93u4389",
        price: 16.32,
        type: "BNB"
    },
    {
        id: 5,
        value: "0x4300sd....93u4389",
        price: 16.32,
        type: "BNB"
    },
    {
        id: 6,
        value: "0x4300sd....93u4389",
        price: 16.32,
        type: "BNB"
    },
]