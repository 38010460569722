import React, { useState, useLayoutEffect } from "react";
// assets
import banner from "../../assets/images/predictionbg.png";
import leftArrow from "../../assets/icons/leftArrow.png";
import PredictionCards from "../../components/cards/PredictionCards/PredictionCards";
import FinishedCard from "../../components/cards/PredictionCards/FinishedCard";
import { Position } from "contracts/constants/index";

const PredictionTabs = ({ setShowTabComp, value, leagueValue, eventList, balanceArray, setIsLoading, setIsAuthModalVisible, getEventList, getTokenBalances }) => {
  const [activeTab, setActiveTab] = useState("0");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    // console.log('====================================');
    // console.log('eventList', eventList);
    // console.log('====================================');
  }, []);
  return (
    <>
      <div className="prediction_wrap">
        <div className="pw_banner">
          <img src={banner} alt="banner" />
          <div className="pwb_wrap">
            <div>
              {/* <img src={leagueValue[0]} alt="football" /> */}
              <h2>{leagueValue[1]}</h2>
            </div>
          </div>
        </div>
        <div className="pw_tabs_wrap">
          <div className="back" onClick={() => setShowTabComp(false)}>
            <img src={leftArrow} alt="leftArrow" />
            <span>Back</span>
          </div>
          <div className="pw_tabs">
            <div
              className={activeTab === "0" ? "active_tab" : "non_activeTab"}
              onClick={() => setActiveTab("0")}
            >
              Active
            </div>
            <div
              className={activeTab === "1" ? "active_tab" : "non_activeTab"}
              onClick={() => setActiveTab("1")}
            >
              Live
            </div>
            <div
              className={activeTab === "2" ? "active_tab" : "non_activeTab"}
              onClick={() => setActiveTab("2")}
            >
              Finished
            </div>
          </div>
        </div>
        {activeTab === "0" ? (
          <>
            <div className="pcard_wrap">
              {
                eventList.filter((item) => item.result.toString() === Position.CREATED || item.result.toString() === Position.LIVE).map((match, i) => (
                  <PredictionCards
                    key={i}
                    value={value}
                    match={match}
                    balanceArray={balanceArray}
                    setIsLoading={setIsLoading}
                    leagueValue={leagueValue[1]}
                    setIsAuthModalVisible={setIsAuthModalVisible}
                    getEventList={getEventList}
                    getTokenBalances={getTokenBalances}
                  />
                ))
              }
            </div>
          </>
        ) : activeTab === "1" ? (
          <div className="pcard_wrap">
            {
              eventList.filter((item) => item.result.toString() === Position.STOP).map((match, i) => (
                <FinishedCard
                  key={i}
                  value={value}
                  match={match}
                  balanceArray={balanceArray}
                  setIsLoading={setIsLoading}
                  leagueValue={leagueValue[1]}
                  setIsAuthModalVisible={setIsAuthModalVisible}
                />
              ))
            }
          </div>
        ) : (
          <div className="pcard_wrap">
            {
              eventList.filter((item) =>
                item.result.toString() === Position.outcomeA ||
                item.result.toString() === Position.outcomeB ||
                item.result.toString() === Position.outcomeC ||
                item.result.toString() === Position.CANCEL
              ).map((match, i) => (
                <FinishedCard
                  key={i}
                  value={value}
                  match={match}
                  balanceArray={balanceArray}
                  setIsLoading={setIsLoading}
                  leagueValue={leagueValue[1]}
                  setIsAuthModalVisible={setIsAuthModalVisible}
                  getEventList={getEventList}
                />
              ))
            }
          </div>
        )}
      </div>
    </>
  );
};

export default PredictionTabs;
