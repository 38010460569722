import LeagueABI from './abi/leagueAbi.json';
import BettingABI from './abi/bettingAbi.json';
// import MuftABI from './abi/muftABI.json';
// import MswapABI from './abi/mswapABI.json';
// import WbnbABI from './abi/wbnbABI.json';
import GenericErc20Abi from './abi/Erc20.json'

// contract, token testnet address
// export const contractAddress = "0x1cccD8d9350eCb6323C5E84de0f07FC1fDC95BcD"
// export const bettingContractAddress = "0x766FaC576122C63F0b9Ef7C84b369723000Bd8bf"
// export const mswapAddress = "0x8d3eFeF5283BEDAcBbe7B6a616f60E8612C7c7ef"
// export const muftAddress = "0x223c7646a3ad04dafF69A8fe25fCdFCe1ec311c2"

// contract, token mainnet address
export const contractAddress = "0x7B38D1F426F685C5EC050c6b547648FCEcbb0715" // league address
export const bettingContractAddress = "0x101f8e9AACc845eb184eE5A595AC3E625EEa66EF"
export const mswapAddress = "0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B"
export const muftAddress = "0x1D4a26a9D980AFC2287Dca15eb896A26384bAc7c"

export const bnbMuftLP = "0x8858cFb3f8C901381bd52F8aCf5bFB888dce421d"
export const bnbMswapLP = "0x90Ebf5bF9e0486893deBC85Dcf164d1dDC107e0c"
// export const wbnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"

export const leagueABI = LeagueABI;
export const bettingABI = BettingABI;
// export const muftABI = MuftABI;
// export const mswapABI = MswapABI;
// export const wbnbABI = WbnbABI;
export const genericErc20Abi = GenericErc20Abi;
export const approveAmount = "10000000000000000000000000000000000000000000";

export const  mswapPriceAPI = "https://api.coinpaprika.com/v1/coins/mswap-muftswap-token/markets"
export const  muftPriceAPI = "https://api.coinpaprika.com/v1/coins/muft-manutd-fan-token/markets"
export const  bnbPriceAPI = "https://api.coinpaprika.com/v1/coins/bnb-binance-coin/markets"

export const statusArray = ['team1 won', 'team2 won', 'draw', 'LIVE', 'STOPPED', 'CANCELED', 'CREATED']
export const BaseToken = {
    "BNB": "0",
    "MSWAP": "1",
    "MUFT": "2"
}
export const Position = {
    outcomeA: "0",
    outcomeB: "1",
    outcomeC: "2",
    LIVE: "3",
    STOP: "4",
    CANCEL: "5",
    CREATED: "6"
}
export const minAmount = {
    "BNB": 0.005,
    "MSWAP": 10000,
    "MUFT": 2000000
}