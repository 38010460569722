import React, { useState, useEffect } from "react";
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis'
import { Menu, Layout, Button, Dropdown, Alert, Space, Drawer, message, Modal, Row, Col } from "antd";
import yellow_vector from "../../../assets/images/yellow_vector.png";
import "./PredictionCards.css";
import { minAmount, Position, BaseToken, mswapAddress, muftAddress, approveAmount, bettingContractAddress, bettingABI } from "contracts/constants/index";
import bnb from "../../../assets/icons/bnb.png";
import mswap from "../../../assets/icons/mswap.png";
import muft from "../../../assets/icons/muft.png";

const FinishedCard = ({ value, match, balanceArray, setIsLoading, leagueValue, setIsAuthModalVisible, getEventList }) => {
  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, Moralis, user, isInitialized } = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();
  const [activeBet, setActiveBet] = useState(null);

  useEffect(() => {
    console.log('====================================');
    console.log('match', match);
    console.log('====================================');
  }, [])

  const getTotalPrize = () => {
    switch (value) {
      case "BNB":
        return parseFloat(Moralis.Units.FromWei(match.totalPrizeBnb)).toFixed(2)
      case "MSWAP":
        return parseFloat(Moralis.Units.FromWei(match.totalPrizeMswap)).toFixed(2)
      case "MUFT":
        return parseFloat(Moralis.Units.FromWei(match.totalPrizeMuft)).toFixed(2)
      default:
        break;
    }
  }

  const getAPR = (team) => {
    if (value === "BNB") {
      switch (team) {
        case 1:
          return match.poolSizeBnbA === 0 ? 0 : match.totalPrizeBnb / match.poolSizeBnbA
        case 2:
          return match.poolSizeBnbC === 0 ? 0 : match.totalPrizeBnb / match.poolSizeBnbC
        case 3:
          return match.poolSizeBnbB === 0 ? 0 : match.totalPrizeBnb / match.poolSizeBnbB
        default:
          break;
      }
    } else if (value === "MSWAP") {
      switch (team) {
        case 1:
          return match.poolSizeMswapA === 0 ? 0 : match.totalPrizeMswap / match.poolSizeMswapA
        case 2:
          return match.poolSizeMswapC === 0 ? 0 : match.totalPrizeMswap / match.poolSizeMswapC
        case 3:
          return match.poolSizeMswapB === 0 ? 0 : match.totalPrizeMswap / match.poolSizeMswapB
        default:
          break;
      }
    } else if (value === "MUFT") {
      switch (team) {
        case 1:
          return match.poolSizeMuftA === 0 ? 0 : match.totalPrizeMuft / match.poolSizeMuftA
        case 2:
          return match.poolSizeMuftC === 0 ? 0 : match.totalPrizeMuft / match.poolSizeMuftC
        case 3:
          return match.poolSizeMuftB === 0 ? 0 : match.totalPrizeMuft / match.poolSizeMuftB
        default:
          break;
      }
    }
  }

  const teamData = [
    { id: 1, teamName: match.eventTitle.split("VS")[0], value: getAPR(1), betNumber: "0" },
    { id: 2, teamName: "DRAW", value: getAPR(2), betNumber: "2" },
    { id: 3, teamName: match.eventTitle.split("VS")[1], value: getAPR(3), betNumber: "1" },
  ];

  function getBetStatus(team) {
    if (team === match.position) return true
    else return false
  }

  function getBetedImage() {
    switch (match.baseToken) {
      case BaseToken.BNB:
        return bnb
      case BaseToken.MSWAP:
        return mswap
      case BaseToken.MUFT:
        return muft
      default:
        break;
    }
  }

  async function handleClaim(functionName) {
    if (!isInitialized || !user || !isWeb3Enabled || !isAuthenticated) return
    if (functionName === "claim" && (match.result).toString() !== match.position) {
      message.error({
        content: 'Sorry, your prediction was incorrect!',
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return
    }
    if (functionName === "claim_forcancel" && !match.isClaimed && match.position === "-1") {
      message.error({
        content: 'You didn\'t bet on this match!',
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return
    }
    if (match.isClaimed) {
      message.success({
        content: 'You have already claimed!',
        className: 'custom-class',
        style: {
          marginTop: '60px',
        },
      });
      return
    }
    try {
      setIsLoading(true)
      // claim rewards
      const params = {
        contractAddress: bettingContractAddress,
        functionName: functionName,
        abi: bettingABI,
        params: {
          leagueTitle: leagueValue, // league title: League A
          eventId: match.eventId, // event id: 0, 1, 2, 3 ...
          token: match.baseToken, // token: BNB, MSWAP, MUFT
        },
      }
      console.log('====================================');
      console.log(params);
      console.log('====================================');
      await contractProcessor.fetch({
        params,
        onSuccess: async (result) => {
          console.log('==>>', result);
          message.success({
            content: `Claimed successfully!`,
            className: 'custom-class',
            style: {
              marginTop: '60px',
            },
          });
          setIsLoading(false)
          setTimeout(() => {
            getEventList(match.leagueId)
          }, 1000);
        },
        onError: (err) => {
          setIsLoading(false)
          console.log("Claimed onError=>", err);
        }
      });
    } catch (err) {
      setIsLoading(false)
      console.log('placeBetBNB catch error=>', err)
    }
  }

  return (
    <>
      <div className="prediction_card finished_card">
        <h2>
          <label className="prediction-result-num">({match.scoreA}){' '}</label>
          {match.eventTitle}
          <label className="prediction-result-num">{' '}({match.scoreB})</label>
        </h2>
        {
          match.result.toString() === Position.CANCEL &&
          <p className="game-cancelled-info">Game cancelled.</p>
        }
        <hr />

        <div className="p_3">
          <hr />
        </div>
        <div className="fc_4">
          <div>
            <strong>Prize Pool:</strong>
            <p>{getTotalPrize()} {value}</p>
          </div>
          {
            teamData.map((d, i) => (
              <div key={i} className="fc_box">
                {
                  match.result.toString() === d.betNumber &&
                  <img src={yellow_vector} alt="..." />
                }
                {
                  (isAuthenticated || account) && getBetStatus(d.betNumber) &&
                  <>
                    <div id="deposited-team">
                      <img src={getBetedImage()} alt="..." />
                      {/* <h5 style={{ color: "#ff2a3a" }}>BET</h5> */}
                    </div>
                  </>
                }
                <button
                  className="activeFinishTab"
                  onClick={() =>
                    setActiveBet((prev) => (prev === "0" ? null : "0"))
                  }
                >
                  {d.teamName}
                </button>
                <p>{d.value.toFixed(2)} x Payout</p>
              </div>
            ))
          }
        </div>
        {
          (match.result).toString() !== Position.STOP &&
          <div className="p_4">
            {
              match.result.toString() !== Position.CANCEL &&
              <button
                style={{
                  backgroundColor: (match.result).toString() !== match.position ? "gray" : match.isClaimed ? "#ff2a3a55" : "#ff2a3a",
                  color: 'white'
                }}
                onClick={() => handleClaim("claim")}>
                {(match.result).toString() !== match.position ? "You can not claim" : match.isClaimed ? "Already claimed" : "Claim"}
              </button>
            }
            {
              match.result.toString() === Position.CANCEL &&
              <button
                style={{
                  backgroundColor: match.isClaimed ? "#ff2a3a55" : match.position === "-1" ? "gray" : "#ff2a3a",
                  color: 'white'
                }}
                onClick={() => handleClaim("claim_forcancel")}>
                {match.isClaimed ? "Already claimed" : match.position === "-1" ? "You can not claim" : "Claim"}
              </button>
            }
          </div>
        }
      </div>
    </>
  );
};

export default FinishedCard;
