import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Alert, Space, message, Modal } from "antd";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Text from "antd/lib/typography/Text";
import { useMoralis, useWeb3ExecuteFunction, useMoralisWeb3Api } from 'react-moralis'
import { genericErc20Abi, mswapAddress, muftAddress, bettingContractAddress, bettingABI, mswapPriceAPI, muftPriceAPI, bnbPriceAPI } from 'contracts/constants/index';
// import { useBnbPrice, useMswapPrice, useMuftPrice } from 'hooks/useTokenPrice';
import particlesConfig from 'contracts/constants/particlesConfigRed';
import { connectors } from "helpers/config";
import banner from "../../assets/images/banner.png";
import homeFootball from "../../assets/images/homeFootball.png";
import homeBasketball from "../../assets/images/homeBasketball.jpg";
import homeTennis from "../../assets/images/homeTennis.png";
import homeBoxing from "../../assets/images/homeBoxing.png";
import homeEsports from "../../assets/images/homeEsports.svg";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: "#21BF96",
  },
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
};

const IconContainer = styled.div`
  width: 100%;
  background-color: #4A0404;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 3em 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`
const InfoCard = styled.div`
  width: 18%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 0.5em;
  cursor: pointer;
  max-height: 143px;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
  }
  h1 {
    color: #c70101;
    width: 60%;
    text-align: center;
    font-size: 18px;
  }
  &:hover {
    transform: scale(1.05);
    transition: transform ease 0.2s;
  }
`
const ComingSoonDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #c70101;
  position: absolute;
  top: 10px;
  right: 10px;
`
const AvatarImage = styled.img`
  width: 60px;
  height: 60px;
  margin: 1em;
  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const FootballHomePage = ({ isAuthModalVisible, setIsAuthModalVisible, setIsLoading, value }) => {
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, logout, user, isInitialized, Moralis } = useMoralis();

  const connectWallet = async (connectorId) => {
    try {
      if (!web3.givenProvider) {
        setIsError(true)
        setIsAuthModalVisible(false);
        return;
      }
      setIsLoading(true)
      await authenticate({ provider: connectorId });
      window.localStorage.setItem("connectorId", connectorId);
      setIsAuthModalVisible(false);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.error(e);
      message.error("SignIn Failed")
    }
  }

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
  };

  return (
    <div className="home-sports-container">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesConfig}
      />
      {(!isAuthenticated || !account) &&
        <Modal
          visible={isAuthModalVisible}
          footer={null}
          onCancel={() => setIsAuthModalVisible(false)}
          bodyStyle={{
            padding: "15px",
            fontSize: "17px",
            fontWeight: "500",
          }}
          style={{ fontSize: "16px", fontWeight: "500" }}
          width="340px"
        >
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            Connect Wallet
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {connectors.map(({ title, icon, connectorId }, key) => (
              <div
                style={styles.connector}
                key={key}
                onClick={() => connectWallet(connectorId)}
              >
                <img src={icon} alt={title} style={styles.icon} />
                <Text style={{ fontSize: "14px" }}>{title}</Text>
              </div>
            ))}
          </div>
        </Modal>
      }

      {isError && <Alert
        message="Provider Error"
        description="No provider was found"
        type="error"
        action={
          <Space direction="vertical" align="end">
            <span onClick={() => setIsError(false)} style={{ cursor: "pointer" }}>
              <CloseOutlined />
            </span>
          </Space>
        }
      />}
      <div className="home-banner-img-container">
        <img src={banner} alt="banner" />
      </div>
      <div className="home_fpw_content sub-sports-title">
        <h1>
          Earn tokens by placing predictions
        </h1>
        <h4 style={{ color: 'white' }} className="fpw_description">Sports Predictions (SP) Predict-to-Earn is a decentralised protocol that aims to bring further innovation in the crypto-sports gaming field</h4>
      </div>
      <h1 style={{ color: 'white', textAlign: 'center', marginTop: '2em' }} >Sports Predictions</h1>
      <IconContainer>
        <InfoCard onClick={() => history.push("/football")}>
          <AvatarImage src={homeFootball} alt="homeFootball" />
          <h1>Football</h1>
        </InfoCard>
        <InfoCard onClick={() => history.push("/basketball")}>
          <ComingSoonDot />
          <AvatarImage src={homeBasketball} alt="homeBasketball" />
          <h1>Basketball</h1>
        </InfoCard>
        <InfoCard onClick={() => history.push("/tennis")}>
          <ComingSoonDot />
          <AvatarImage src={homeTennis} alt="homeTennis" />
          <h1>Tennis</h1>
        </InfoCard>
        <InfoCard onClick={() => history.push("/boxing")}>
          <ComingSoonDot />
          <AvatarImage src={homeBoxing} alt="homeBoxing" />
          <h1>Boxing & UFC</h1>
        </InfoCard>
        <InfoCard onClick={() => history.push("/esports")}>
          <ComingSoonDot />
          <AvatarImage src={homeEsports} alt="homeEsports" />
          <h1>E-Sports</h1>
        </InfoCard>
      </IconContainer>
    </div>
  );
};

export default FootballHomePage;
