import React, { useState, useEffect } from "react";
import { Alert, Space, message, Modal } from "antd";
import Web3 from 'web3'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Text from "antd/lib/typography/Text";
import { useMoralis, useWeb3ExecuteFunction, useMoralisWeb3Api } from 'react-moralis'
import { genericErc20Abi, mswapAddress, muftAddress, mswapPriceAPI, muftPriceAPI, bnbPriceAPI, bnbMuftLP, bnbMswapLP } from 'contracts/constants/index';
// import { useBnbPrice, useMswapPrice, useMuftPrice } from 'hooks/useTokenPrice';
import particlesConfig from 'contracts/constants/particlesConfigRed';
import PredictionTabs from "./PredictionTabs";
import PrizesAndRanks from "./PrizesAndRanks";
import { connectors } from "helpers/config";
import "./style.css";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: "#21BF96",
  },
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
};

const FootballPrediction = ({ isAuthModalVisible, setIsAuthModalVisible, setIsLoading, value }) => {
  const contractProcessor = useWeb3ExecuteFunction();
  const [showTabComp, setShowTabComp] = useState(false);
  const [leagueValue, setLeagueValue] = useState([]);
  const [isError, setIsError] = useState(false);
  const [leagueList, setLeagueList] = useState([])
  const [eventList, setEventList] = useState([])
  const [myBnbBalance, setMyBnbBalance] = useState(0)
  const [myMswapBalance, setMyMswapBalance] = useState(0)
  const [myMuftBalance, setMyMuftBalance] = useState(0)
  const [count, setCount] = useState(0)
  const [totalPayouts, setTotalPayouts] = useState(0)

  const { isWeb3Enabled, enableWeb3, authenticate, isAuthenticated, isWeb3EnableLoading, web3, account, logout, user, isInitialized, Moralis } = useMoralis();
  const Web3Api = useMoralisWeb3Api();

  const connectWallet = async (connectorId) => {
    try {
      if (!web3.givenProvider) {
        setIsError(true)
        setIsAuthModalVisible(false);
        return;
      }
      setIsLoading(true)
      await authenticate({ provider: connectorId });
      window.localStorage.setItem("connectorId", connectorId);
      setIsAuthModalVisible(false);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.error(e);
      message.error("SignIn Failed")
    }
  }

  async function getLeagues() {
    if (!isInitialized) return;
    try {
      const leagues = await Moralis.Cloud.run("getLeagues");
      // console.log('Football Prediction Page leagues=>', leagues)
      setLeagueList(leagues)
      setCount(prevCount => prevCount + 1)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log('getLeagues catch err=>', err)
    }
  }

  async function getEventList(id) {
    // if (!isInitialized) return;
    try {
      const walletAddress = (isInitialized && user) ? user.get("ethAddress") : undefined
      setIsLoading(true)
      const params = {
        leagueId: id,
        player: walletAddress
        // baseToken: BaseToken[`${}`]
      }
      const events = await Moralis.Cloud.run("getEvents", params);
      // console.log('Football Prediction Page events=>', events)
      setEventList(events.reverse())
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log('Football Prediction Page getEventList err=>', err)
    }
  }

  const getOption = (token) => {
    // user wallet address
    const walletAddress = user.get("ethAddress")
    const ops = {
      contractAddress: token === "mswap" ? mswapAddress : muftAddress,
      functionName: "balanceOf",
      abi: genericErc20Abi, // mswap testnet contract ABI
      params: {
        account: walletAddress
      },
    };
    return ops;
  }

  async function getMswapBalance() {
    if (!isInitialized || !user || !isWeb3Enabled) return
    // get mswap balance
    await contractProcessor.fetch({
      params: getOption('mswap'),
      onSuccess: (res) => {
        const mswapBalance = Moralis.Units.FromWei(res)
        // console.log('getMswapBalance=>', mswapBalance);
        setMyMswapBalance(mswapBalance.toFixed(2))
        setCount(prevCount => prevCount + 1)
      },
      onError: (error) => {
        console.log('getMswapBalance err=>', error);
      },
    });
  }
  async function getMuftBalance() {
    if (!isInitialized || !user || !isWeb3Enabled) return
    // get muft balance
    await contractProcessor.fetch({
      params: getOption('muft'),
      onSuccess: (res) => {
        const muftBalance = Moralis.Units.FromWei(res)
        // console.log('getMuftBalance=>', muftBalance)
        setMyMuftBalance(muftBalance.toFixed(2))
        setCount(prevCount => prevCount + 1)
      },
      onError: (error) => {
        console.log('getMuftBalance err=>', error);
      },
    });
  }
  async function getBnbalance() {
    const walletAddress = user.get("ethAddress")
    /*
    // get mainnet native balance for the current user
    const balance = await Web3Api.account.getNativeBalance();
    console.log('get mainnet native balance =>', balance);
    */
    // get BSC native balance for a given address
    const options = {
      chain: "bsc", ////// must be changed to mainnet later. (testnet: "bsc testnet")
      address: walletAddress,
    };
    const bnbBalanceInWei = await Web3Api.account.getNativeBalance(options);
    const bnbBalance = Moralis.Units.FromWei(bnbBalanceInWei.balance)
    setMyBnbBalance(bnbBalance.toFixed(4))
    setCount(prevCount => prevCount + 1)
  }

  const getTokenBalances = () => {
    if (!isInitialized || !user) return
    getMswapBalance()
    getMuftBalance()
    getBnbalance()
  }

  const getTotalPayouts = async (priceBNB, priceMSWAP, priceMUFT) => {
    // if (!isInitialized || !user) return
    try {
      const params = {
        player: "admin"
      }
      const totalPayoutsObj = await Moralis.Cloud.run("getTotalAmount", params);
      // console.log('====================================');
      // console.log('bnb', priceBNB);
      // console.log('mswap', priceMSWAP);
      // console.log('muft', priceMUFT);
      // console.log('====================================');
      const totalPayoutsTemp =
        priceBNB * Moralis.Units.FromWei(totalPayoutsObj.bnb) +
        priceMSWAP * Moralis.Units.FromWei(totalPayoutsObj.mswap) +
        priceMUFT * Moralis.Units.FromWei(totalPayoutsObj.muft)
      // console.log('totalPayoutsTemp2000=>', totalPayoutsTemp);
      setTotalPayouts(parseInt(totalPayoutsTemp))

    } catch (err) {
      setIsLoading(false)
      console.log('totalPayoutsTemp=>', err)
    }
  }

  const getPairPriceOption = (token) => {
    const options = {
      pair_address: token === "mswap" ? bnbMswapLP : bnbMuftLP,
      chain: "bsc",
    };
    return options
  }

  const getTokensPrice = () => {
    fetch(bnbPriceAPI)
      .then((res) => res.json())
      .then(async resJson => {
        const mswapReserves = await Web3Api.defi.getPairReserves(getPairPriceOption('mswap')); //{reserve0: '15634558164911470295257328', reserve1: '4781322433858722033'}
        const muftReserves = await Web3Api.defi.getPairReserves(getPairPriceOption('muft')); //{reserve0: '15634558164911470295257328', reserve1: '4781322433858722033'}
        const bnbPriceTemp = resJson[0].quotes.USD.price; // BNB price
        const mswapPriceTemp = bnbPriceTemp * Moralis.Units.FromWei(mswapReserves.reserve1) / Moralis.Units.FromWei(mswapReserves.reserve0);
        const muftPriceTemp = bnbPriceTemp * Moralis.Units.FromWei(muftReserves.reserve1) / Moralis.Units.FromWei(muftReserves.reserve0);
        // console.log("tokens price=>", bnbPriceTemp, mswapPriceTemp, muftPriceTemp);
        getTotalPayouts(bnbPriceTemp, mswapPriceTemp, muftPriceTemp)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    getLeagues()
    getTokenBalances()
    getTokensPrice()
    // getMswapPrice()
    // getMuftPrice()
  }, [isInitialized, user, isWeb3Enabled, showTabComp, isAuthenticated])

  useEffect(() => {
    // console.log('count=>', count);
    if (count >= 4) {
      // console.log('loading false here=====');
      setIsLoading(false)
    }
  }, [count])

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
  };

  return (
    <div className="footbalPrediction_wrapper">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesConfig}
      />
      {(!isAuthenticated || !account) &&
        <Modal
          visible={isAuthModalVisible}
          footer={null}
          onCancel={() => setIsAuthModalVisible(false)}
          bodyStyle={{
            padding: "15px",
            fontSize: "17px",
            fontWeight: "500",
          }}
          style={{ fontSize: "16px", fontWeight: "500" }}
          width="340px"
        >
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            Connect Wallet
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {connectors.map(({ title, icon, connectorId }, key) => (
              <div
                style={styles.connector}
                key={key}
                onClick={() => connectWallet(connectorId)}
              >
                <img src={icon} alt={title} style={styles.icon} />
                <Text style={{ fontSize: "14px" }}>{title}</Text>
              </div>
            ))}
          </div>
        </Modal>
      }

      {isError && <Alert
        message="Provider Error"
        description="No provider was found"
        type="error"
        action={
          <Space direction="vertical" align="end">
            <span onClick={() => setIsError(false)} style={{ cursor: "pointer" }}>
              <CloseOutlined />
            </span>
          </Space>
        }
      />}

      {!showTabComp ? (
        <PrizesAndRanks
          setLeagueValue={setLeagueValue}
          setShowTabComp={setShowTabComp}
          leagueList={leagueList}
          getEventList={(id) => getEventList(id)}
          totalPayouts={totalPayouts}
        />
      ) : (
        <PredictionTabs
          leagueValue={leagueValue}
          value={value}
          setShowTabComp={setShowTabComp}
          eventList={eventList}
          balanceArray={[myBnbBalance, myMswapBalance, myMuftBalance]}
          setIsLoading={setIsLoading}
          setIsAuthModalVisible={setIsAuthModalVisible}
          getEventList={(id) => getEventList(id)}
          getTokenBalances={getTokenBalances}
        />
      )}
    </div>
  );
};

export default FootballPrediction;
