import { message } from "antd";

export default function showMessage(msg, status) {
    if (status === "success") {
        message.success({
            content: msg,
            className: 'custom-class',
            style: {
                marginTop: '60px',
            },
        });
    } else {
        message.error({
            content: msg,
            className: 'custom-class',
            style: {
                marginTop: '60px',
            },
        });
    }
}