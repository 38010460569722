import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import FootballPrediction from './pages/FootballPrediction/index';
import TennisPrediction from './pages/TennisPrediction/index';
import BoxingPrediction from './pages/BoxingPrediction/index';
import EsportsPrediction from './pages/EsportsPrediction/index';
import BasketballPrediction from './pages/BasketballPrediction/index';
import FootballHomePage from './pages/FootballHomePage/index';
import MswapAdmin from './pages/MswapAdmin/index';
import EventList from './pages/EventList/index';
// Layout
import Sidebar from './layout/Sidebar/Sidebar';
import TopBar from './layout/Sidebar/TopBar';
// Data
import { data } from "./data/DropDownData"
import PageLoader from 'components/PageLoader'

const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading, user, isInitialized } = useMoralis();
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const [value, setValue] = useState([data[0].img, data[0].name]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    console.log('App isAuthModalVisible=>', isAuthModalVisible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled, isAuthModalVisible, user, isInitialized]);



  return (
    <>
      {isLoading && <PageLoader />}
      <TopBar data={data} value={value} setValue={setValue} setIsAuthModalVisible={() => setIsAuthModalVisible(true)} />
      <div className="main">
        <BrowserRouter>
          <Sidebar />
          <Switch>
            <Route exact path="/">
              <FootballHomePage
                value={value[1]}
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route exact path="/football">
              <FootballPrediction
                value={value[1]}
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route exact path="/basketball">
              <BasketballPrediction
                value={value[1]}
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route exact path="/tennis">
              <TennisPrediction
                value={value[1]}
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route exact path="/boxing">
              <BoxingPrediction
                value={value[1]}
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route exact path="/esports">
              <EsportsPrediction
                value={value[1]}
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route exact path="/admin">
              <MswapAdmin
                isAuthModalVisible={isAuthModalVisible}
                setIsAuthModalVisible={(e) => setIsAuthModalVisible(e)}
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
            <Route path="/admin/:leagueId">
              <EventList
                setIsLoading={(e) => setIsLoading(e)}
              />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
